import Konva from 'konva'
import { Group as GroupShape } from 'konva/lib/Group'
import { Stage as StageShape } from 'konva/lib/Stage'
import { Image as ImageShape } from 'konva/lib/shapes/Image'
import { Transformer as TransformerShape } from 'konva/lib/shapes/Transformer'
import { Ref, useEffect, useRef, useState } from 'react'
import { Image, Layer, Rect, Stage, Transformer } from 'react-konva'
import useImage from 'use-image'
import useCursorStyleOnHover from '../hooks/useCursorStyleOnHover'
import Folder from '../utils/Folder'
import ImageInfo from '../utils/ImageInfo'
import ClippingMask from './ClippingMask'
import GroupShadow from './FolderShadow'

interface Props {
  imagePath?: string
  titlePath?: string
  showFullImage?: boolean
  showTitleResizeOption?: boolean
  stageRef?: Ref<StageShape>
  invertTitle?: boolean
}

const Editor = ({
  imagePath,
  titlePath,
  showFullImage = false,
  showTitleResizeOption = false,
  stageRef,
  invertTitle = false
}: Props) => {
  // container
  const containerWidth = window.innerWidth
  const containerHeight = window.innerHeight

  // folder
  const folder = new Folder()
  const [folderX, folderY] = folder.centerAt(
    containerWidth / 2,
    containerHeight / 2
  )

  // background image
  const prevImagePath = useRef<string>()
  const [image] = useImage(imagePath ?? '')
  const imageRef = useRef<ImageShape>(null)
  const imageTransformerRef = useRef<TransformerShape>(null)
  const backgroundImageRef = useRef<ImageShape>(null)
  const imageInfo = new ImageInfo(image)
  const [imageWidth, imageHeight] = imageInfo.fitToContainer(
    folder.width,
    folder.height
  )
  const [[imageX, imageY], setImagePosition] = useState([folderX, folderY])
  const [[imageScaleX, imageScaleY], setImageScale] = useState([1, 1])
  const [imageRotation, setImageRotation] = useState(0)

  // folder top image
  const imageTopRef = useRef<ImageShape>(null)

  useEffect(() => {
    if (image) {
      if (prevImagePath.current !== imagePath) {
        setImagePosition([
          containerWidth / 2 - imageWidth / 2,
          containerHeight / 2 - imageHeight / 2 + folder.topOffset
        ])
        setImageScale([1, 1])
        setTimeout(() => {
          imageRef.current?.cache()
          imageTopRef.current?.cache()
          backgroundImageRef.current?.cache()
        })

        prevImagePath.current = imagePath
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [image])

  useEffect(() => {
    if (backgroundImageRef.current) {
      imageTransformerRef.current?.nodes([backgroundImageRef.current])
      imageTransformerRef.current?.getLayer()?.batchDraw()
    }
  }, [showFullImage])

  // title
  const titleRef = useRef<ImageShape>(null)
  const titleTransformerRef = useRef<TransformerShape>(null)
  const [title] = useImage(titlePath ?? '')
  const titleInfo = new ImageInfo(title)
  const [titleWidth, titleHeight] = titleInfo.fitToContainerByWidth(
    folder.titleWidth
  )
  const [titleX, titleY] = folder.centerTitleAt(
    containerWidth / 2,
    containerHeight / 2
  )
  useEffect(() => {
    if (title) {
      titleRef.current?.cache()
    }
  }, [title])

  useEffect(() => {
    if (titleRef.current) {
      titleTransformerRef.current?.nodes([titleRef.current])
      titleTransformerRef.current?.getLayer()?.batchDraw()
    }
  }, [showTitleResizeOption])

  // background shadow
  const folderTopGroupRef = useRef<GroupShape>(null)
  const folderBodyGroupRef = useRef<GroupShape>(null)
  const backgroundShadowUpdateDeps = [image, imageX, imageY]

  // cursor style
  const hoverCursorStyleProps = useCursorStyleOnHover()

  return (
    <Stage ref={stageRef} width={containerWidth} height={containerHeight}>
      {/* Background Shadow */}
      <Layer>
        <GroupShadow
          groupRef={folderTopGroupRef}
          updateDeps={backgroundShadowUpdateDeps}
        />
        <GroupShadow
          groupRef={folderBodyGroupRef}
          updateDeps={backgroundShadowUpdateDeps}
        />
      </Layer>

      {/* Full Image */}
      <Layer>
        {/* Transformer (Image Resize Bounding Box) */}
        {showFullImage && (
          <Transformer
            ref={imageTransformerRef}
            boundBoxFunc={(oldBox, newBox) => {
              // limit resize
              if (newBox.width < 5 || newBox.height < 5) {
                return oldBox
              }
              return newBox
            }}
          />
        )}
        <Image
          ref={backgroundImageRef}
          onTransformStart={() => {
            imageRef.current?.clearCache()
            imageTopRef.current?.clearCache()
            backgroundImageRef.current?.clearCache()
          }}
          onTransform={() => {
            if (backgroundImageRef.current) {
              setImagePosition([
                backgroundImageRef.current.x(),
                backgroundImageRef.current.y()
              ])
              setImageScale([
                backgroundImageRef.current.scaleX(),
                backgroundImageRef.current.scaleY()
              ])
              setImageRotation(backgroundImageRef.current.rotation())
            }
          }}
          onTransformEnd={() => {
            imageRef.current?.cache()
            imageTopRef.current?.cache()
            backgroundImageRef.current?.cache()
          }}
          opacity={showFullImage ? 0.3 : 0}
          x={imageX}
          y={imageY}
          width={imageWidth}
          height={imageHeight}
          rotation={imageRotation}
          image={image}
          draggable
          onDragMove={e => {
            setImagePosition([e.target.x(), e.target.y()])
          }}
          {...hoverCursorStyleProps}
        />
      </Layer>

      {/* Folder Top */}
      <Layer>
        <ClippingMask
          x={folderX}
          y={folderY}
          clippingFunction={folder.folderTopClippingFunction}
          groupRef={folderTopGroupRef}
        >
          <Image
            ref={imageTopRef}
            x={imageX}
            y={imageY}
            width={imageWidth * imageScaleX}
            height={imageHeight * imageScaleY}
            rotation={imageRotation}
            image={image}
            draggable
            onDragMove={e => {
              setImagePosition([e.target.x(), e.target.y()])
            }}
          />
        </ClippingMask>
      </Layer>

      {/* Folder Body */}
      <Layer>
        <ClippingMask
          x={folderX}
          y={folderY}
          clippingFunction={folder.folderBodyClippingFunction}
          groupRef={folderBodyGroupRef}
        >
          <Image
            ref={imageRef}
            x={imageX}
            y={imageY}
            width={imageWidth * imageScaleX}
            height={imageHeight * imageScaleY}
            rotation={imageRotation}
            image={image}
            draggable
            onDragMove={e => {
              setImagePosition([e.target.x(), e.target.y()])
            }}
            {...hoverCursorStyleProps}
          />
        </ClippingMask>
      </Layer>

      {/* Folder Top Shadow */}
      <Layer>
        <ClippingMask
          x={folderX}
          y={folderY}
          clippingFunction={folder.folderTopClippingFunction}
        >
          <Rect
            width={imageWidth}
            height={imageHeight}
            x={folderX}
            y={folderY}
            fillPriority="linear-gradient"
            fillLinearGradientStartPoint={{ x: 0, y: folder.topOffset + 50 }}
            fillLinearGradientEndPoint={{ x: 0, y: folder.topOffset + 70 }}
            fillLinearGradientColorStops={[0, 'transparent', 1, 'black']}
          />
        </ClippingMask>
      </Layer>

      {/* Title */}
      <Layer>
        {showTitleResizeOption && (
          <Transformer
            ref={titleTransformerRef}
            boundBoxFunc={(oldBox, newBox) => {
              // limit resize
              if (newBox.width < 5 || newBox.height < 5) {
                return oldBox
              }
              return newBox
            }}
          />
        )}
        <Image
          ref={titleRef}
          image={title}
          filters={invertTitle ? [Konva.Filters.Invert] : []}
          onTransformStart={() => {
            titleRef.current?.clearCache()
          }}
          onTransformEnd={() => {
            titleRef.current?.cache()
          }}
          x={titleX}
          y={titleY}
          width={titleWidth}
          height={titleHeight}
          draggable
          shadowBlur={10}
          {...hoverCursorStyleProps}
        />
      </Layer>
    </Stage>
  )
}

export default Editor
