import { KonvaEventObject } from 'konva/lib/Node'

const useCursorStyleOnHover = (style: string = 'move') => {
  const onMouseEnter = (e: KonvaEventObject<MouseEvent>) => {
    // style stage container:
    const container = e.target.getStage()?.container()
    if (container) container.style.cursor = style
  }
  const onMouseLeave = (e: KonvaEventObject<MouseEvent>) => {
    const container = e.target.getStage()?.container()
    if (container) container.style.cursor = 'default'
  }

  return { onMouseEnter, onMouseLeave }
}

export default useCursorStyleOnHover
