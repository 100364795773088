export function download(uri: string, name: string) {
  console.log(uri)
  var link = document.createElement('a')
  link.download = name
  link.href = uri
  document.body.appendChild(link)
  link.click()
  document.body.removeChild(link)
}

export async function crop(
  dataUri: string,
  finalWidth = 256,
  finalHeight = 256
) {
  return new Promise(resolve => {
    const canvas = document.createElement('canvas')
    canvas.width = window.innerWidth
    canvas.height = window.innerHeight
    const ctx = canvas.getContext('2d') as CanvasRenderingContext2D
    const image = new Image()
    image.onload = function () {
      ctx.drawImage(image, 0, 0)
      const imgd = ctx.getImageData(0, 0, image.width, image.height)
      const pix = imgd.data

      let minX = image.width
      let minY = image.height
      let maxX = 0
      let maxY = 0

      for (let i = 0; i < pix.length; i += 4) {
        const alpha = pix[i + 3]
        if (alpha !== 0) {
          const position = Math.floor(i / 4)
          const x = position % image.width
          const y = Math.floor(position / image.width)
          minX = Math.min(x, minX)
          minY = Math.min(y, minY)
          maxX = Math.max(x, maxX)
          maxY = Math.max(y, maxY)
        }
      }

      const tempCanvas = document.createElement('canvas')
      let width = maxX - minX
      let height = maxY - minY
      const widthHeightDiff = Math.abs(width - height)

      if (width > height) {
        height = width
        minY = minY - widthHeightDiff / 2
        maxY = maxY + widthHeightDiff / 2
      } else {
        width = height
        minX = minX - widthHeightDiff / 2
        maxX = maxX + widthHeightDiff / 2
      }

      tempCanvas.width = finalWidth
      tempCanvas.height = finalHeight
      const tCtx = tempCanvas.getContext('2d') as CanvasRenderingContext2D

      tCtx.drawImage(
        canvas,
        minX,
        minY,
        width,
        height,
        0,
        0,
        finalWidth,
        finalHeight
      )

      resolve(tempCanvas.toDataURL())
    }
    image.src = dataUri
  })
}

export function dataURItoBlob(dataURI: string) {
  // convert base64 to raw binary data held in a string
  // doesn't handle URLEncoded DataURIs - see SO answer #6850276 for code that does this
  var byteString = atob(dataURI.split(',')[1])

  // separate out the mime component
  var mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0]

  // write the bytes of the string to an ArrayBuffer
  var ab = new ArrayBuffer(byteString.length)

  // create a view into the buffer
  var ia = new Uint8Array(ab)

  // set the bytes of the buffer to the correct values
  for (var i = 0; i < byteString.length; i++) {
    ia[i] = byteString.charCodeAt(i)
  }

  // write the ArrayBuffer to a blob, and you're done
  var blob = new Blob([ab], { type: mimeString })
  return blob
}
