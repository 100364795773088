import { SceneContext } from 'konva/lib/Context'
import { Group as GroupShape } from 'konva/lib/Group'
import { PropsWithChildren, RefObject } from 'react'
import { Group } from 'react-konva'

type ClippingMaskProps = PropsWithChildren<{
  x: number
  y: number
  clippingFunction: (
    ctx: SceneContext,
    xoff: number,
    yoff: number,
    xmul: number,
    ymul: number
  ) => void
  groupRef?: RefObject<GroupShape>
}>

const ClippingMask = ({
  x,
  y,
  clippingFunction,
  children,
  groupRef
}: ClippingMaskProps) => {
  return (
    <Group ref={groupRef} clipFunc={ctx => clippingFunction(ctx, x, y, 1, 1)}>
      {children}
    </Group>
  )
}

export default ClippingMask
