import UploadRoundedIcon from '@mui/icons-material/UploadRounded'
import { Button } from '@mui/joy'
import { PropsWithChildren, useRef } from 'react'

interface Props extends PropsWithChildren {
  onFileChange?: (file: File) => void
}

const FileUpload = ({ onFileChange = () => {}, children }: Props) => {
  const inputFile = useRef<HTMLInputElement>(null)

  const handleClick = () => {
    inputFile.current?.click()
  }

  const handleFileChange: React.ChangeEventHandler<HTMLInputElement> = e => {
    if (e.target.files) onFileChange(e.target.files[0])
  }

  return (
    <>
      <Button
        onClick={handleClick}
        variant="outlined"
        startDecorator={<UploadRoundedIcon />}
      >
        {children ?? 'Upload'}
      </Button>
      <input
        onChange={handleFileChange}
        type="file"
        id="file"
        ref={inputFile}
        style={{ display: 'none' }}
      />
    </>
  )
}

export default FileUpload
