import { SceneContext } from 'konva/lib/Context'

class Folder {
  width = 512
  height = 380

  topOffset = 70

  titleOffset = [55, 80]
  titleWidth = 160

  folderTopClippingFunction(
    ctx: SceneContext,
    xoff: number,
    yoff: number,
    xmul: number,
    ymul: number
  ) {
    ctx.beginPath()
    ctx.lineTo(28 * xmul + xoff, 135 * ymul + yoff)
    ctx.lineTo(28 * xmul + xoff, 124 * ymul + yoff)
    ctx.quadraticCurveTo(
      30 * xmul + xoff,
      117 * ymul + yoff,
      38 * xmul + xoff,
      114 * ymul + yoff
    )
    ctx.quadraticCurveTo(
      45 * xmul + xoff,
      108 * ymul + yoff,
      49 * xmul + xoff,
      98 * ymul + yoff
    )
    ctx.quadraticCurveTo(
      48 * xmul + xoff,
      78 * ymul + yoff,
      65 * xmul + xoff,
      73 * ymul + yoff
    )
    ctx.lineTo(204 * xmul + xoff, 73 * ymul + yoff)
    ctx.quadraticCurveTo(
      218 * xmul + xoff,
      76 * ymul + yoff,
      220 * xmul + xoff,
      91 * ymul + yoff
    )
    ctx.quadraticCurveTo(
      222 * xmul + xoff,
      109 * ymul + yoff,
      233 * xmul + xoff,
      114 * ymul + yoff
    )
    ctx.lineTo(474 * xmul + xoff, 114 * ymul + yoff)
    ctx.quadraticCurveTo(
      481 * xmul + xoff,
      116 * ymul + yoff,
      483 * xmul + xoff,
      125 * ymul + yoff
    )
    ctx.lineTo(483 * xmul + xoff, 136 * ymul + yoff)
    ctx.closePath()
  }

  folderBodyClippingFunction(
    ctx: SceneContext,
    xoff: number,
    yoff: number,
    xmul: number,
    ymul: number
  ) {
    ctx.beginPath()
    ctx.lineTo(18 * xmul + xoff, 135 * ymul + yoff)
    ctx.lineTo(494 * xmul + xoff, 135 * ymul + yoff)
    ctx.quadraticCurveTo(
      513 * xmul + xoff,
      141 * ymul + yoff,
      510 * xmul + xoff,
      160 * ymul + yoff
    )
    ctx.lineTo(501 * xmul + xoff, 286 * ymul + yoff)
    ctx.lineTo(498 * xmul + xoff, 372 * ymul + yoff)
    ctx.lineTo(496 * xmul + xoff, 438 * ymul + yoff)
    ctx.quadraticCurveTo(
      496 * xmul + xoff,
      447 * ymul + yoff,
      492 * xmul + xoff,
      448 * ymul + yoff
    )
    ctx.lineTo(21 * xmul + xoff, 448 * ymul + yoff)
    ctx.quadraticCurveTo(
      17 * xmul + xoff,
      448 * ymul + yoff,
      16 * xmul + xoff,
      439 * ymul + yoff
    )
    ctx.lineTo(13 * xmul + xoff, 335 * ymul + yoff)
    ctx.lineTo(9 * xmul + xoff, 247 * ymul + yoff)
    ctx.lineTo(2 * xmul + xoff, 155 * ymul + yoff)
    ctx.quadraticCurveTo(
      9 * xmul + xoff,
      137 * ymul + yoff,
      14 * xmul + xoff,
      137 * ymul + yoff
    )
    ctx.closePath()
  }

  centerAt(x: number, y: number) {
    return [x - this.width / 2, y - this.height / 2]
  }

  centerTitleAt(x: number, y: number) {
    const center = this.centerAt(x, y)
    return [center[0] + this.titleOffset[0], center[1] + this.titleOffset[1]]
  }
}

export default Folder
