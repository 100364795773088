import DownloadRoundedIcon from '@mui/icons-material/DownloadRounded'
import { Box, Button, Checkbox, Typography } from '@mui/joy'
import { Stage } from 'konva/lib/Stage'
import { useRef, useState } from 'react'
import FileUpload from './components/FileUpload'
import { Editor } from './feature/editor'
import { PngIcoConverter } from './png2ico'
import { crop, dataURItoBlob, download } from './utils'

function App() {
  const [imagePath, setImagePath] = useState<string>('main.jpg')
  const [titlePath, setTitlePath] = useState<string>('title.png')
  const [showFullImage, setShowFullImage] = useState(false)
  const [invertTitle, setInvertTitle] = useState(false)
  const [showTitleResizeOption, setShowTitleResizeOption] = useState(false)
  const stageRef = useRef<Stage>(null)

  const handleSelectImage = (file: File) => {
    if (file) {
      setImagePath(URL.createObjectURL(file))
    }
  }

  const handleSelectTitle = (file: File) => {
    if (file) {
      setTitlePath(URL.createObjectURL(file))
    }
  }

  const handleExport = () => {
    const prevShowFullImage = showFullImage
    const prevShowTitleResizeOption = showTitleResizeOption

    setShowFullImage(false)
    setShowTitleResizeOption(false)

    setTimeout(async () => {
      if (stageRef.current) {
        const png = stageRef.current.toDataURL()
        const pngUri = (await crop(png)) as string
        const pngBlob = dataURItoBlob(pngUri)
        const converter = new PngIcoConverter()
        const ico = await converter.convertToBlobAsync([
          { png: pngBlob, ignoreSize: 1 }
        ])
        const uri = URL.createObjectURL(ico)
        download(uri, 'folder.ico')
      }

      setShowFullImage(prevShowFullImage)
      setShowTitleResizeOption(prevShowTitleResizeOption)
    })
  }

  return (
    <>
      <Box
        display="flex"
        flexDirection="column"
        gap={2}
        maxWidth={200}
        position="absolute"
        margin={2}
      >
        <Box display="flex" flexDirection="column" gap={1}>
          <Typography level="h5">1. Image</Typography>
          <Box>
            <FileUpload onFileChange={handleSelectImage}>
              Upload Image
            </FileUpload>
          </Box>
          <Checkbox
            label="Show Full Image"
            checked={showFullImage}
            onChange={e => setShowFullImage(e.target.checked)}
          />
        </Box>
        <Box display="flex" flexDirection={'column'} gap={1}>
          <Typography level="h5">2. Title</Typography>
          <Box>
            <FileUpload onFileChange={handleSelectTitle}>
              Upload Title
            </FileUpload>
          </Box>
          <Box>
            <Checkbox
              label="Show Resize Option"
              checked={showTitleResizeOption}
              onChange={e => setShowTitleResizeOption(e.target.checked)}
            />
          </Box>
          <Box>
            <Checkbox
              label="Invert Color"
              checked={invertTitle}
              onChange={e => setInvertTitle(e.target.checked)}
            />
          </Box>
        </Box>

        <Box display="flex" flexDirection={'column'} gap={1}>
          <Typography level="h5">3. Export</Typography>
          <Box>
            <Button
              variant="outlined"
              onClick={handleExport}
              startDecorator={<DownloadRoundedIcon />}
            >
              Export Folder
            </Button>
          </Box>
        </Box>
      </Box>

      <Box position="fixed" top={0} left={0} zIndex={-1}>
        <Editor
          stageRef={stageRef}
          imagePath={imagePath}
          titlePath={titlePath}
          showFullImage={showFullImage}
          invertTitle={invertTitle}
          showTitleResizeOption={showTitleResizeOption}
        />
      </Box>
    </>
  )
}

export default App
