class ImageInfo {
  width = 5
  height = 5
  ratio = 1

  constructor(image: HTMLImageElement | undefined) {
    if (!image) return
    this.width = image.width
    this.height = image.height
    this.ratio = this.width / this.height
  }

  fitToContainerByWidth(containerWidth: number) {
    const scale = containerWidth / this.width
    const height = this.height * scale
    return [containerWidth, height]
  }

  fitToContainerByHeight(containerHeight: number) {
    const scale = containerHeight / this.height
    const width = this.width * scale
    return [width, containerHeight]
  }

  fitToContainer(
    containerWidth: number,
    containerHeight: number
  ): [number, number] {
    const [w1, h1] = this.fitToContainerByWidth(containerWidth)
    const [w2, h2] = this.fitToContainerByHeight(containerHeight)

    return h1 > containerHeight ? [w1, h1] : [w2, h2]
  }
}

export default ImageInfo
