import { Group as GroupShape } from 'konva/lib/Group'
import { RefObject, useEffect, useState } from 'react'
import { Image } from 'react-konva'

interface Props {
  groupRef: RefObject<GroupShape>
  updateDeps: unknown[]
}

const GroupShadow = ({ groupRef, updateDeps }: Props) => {
  const [canvas, setCanvas] = useState<HTMLCanvasElement>()
  const [pos, setPos] = useState({ x: 0, y: 0 })

  useEffect(() => {
    if (groupRef.current) {
      groupRef.current.visible(true)
      setCanvas(groupRef.current.toCanvas())
      setPos(groupRef.current.getClientRect())
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [groupRef, ...updateDeps])

  return <Image image={canvas} shadowBlur={10} {...pos} />
}

export default GroupShadow
